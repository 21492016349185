<template>
  <div>
    <div class="pay">
      <countdown
              v-if="this.info.isFlag"
              :currentTime="Number(info.currentTime)"
              :startTime="Number(info.startDate)"
              :endTime="Number(info.endDate)"
              :tipText="'expire Time'"
              :tipTextEnd="'Expire Time'"
              :endText="'time out'"
              :dayTxt="' days '"
              :hourTxt="' hours '"
              :minutesTxt="' Mins '"
              :secondsTxt="' Secs '"
              ></countdown>
        <span v-else>no active</span>
      <div class="pay-header">
        <span>
          My Account:
          <em>{{ account }}</em>
        </span>
        <span>
          Balance:
          <em>{{ balance }} B</em>
        </span>
        <a class="fr" @click="linkCardStockPay">
          go to Wallet Topup
          <i class="iconfont iconyoujiantou"></i>
        </a>
      </div>
      <el-row class="container1">
        <el-col :span="9">
          <el-form
            :model="ruleForm"
            ref="ruleForm"
            :rules="rules"
            class="ruleForm"
          >
            <el-form-item class="item">
              <p><i class="iconfont iconzhanghao"></i>Coupon Number</p>
              <el-input
                v-model="ruleForm.cardKey"
                placeholder="Please input Card Secret"
              ></el-input>
            </el-form-item>
            <el-form-item class="item">
              <p><i class="iconfont iconmima"></i>CAPTCHA</p>
              <el-input
                v-model="ruleForm.imageCode"
                placeholder="Enter captcha code here"
              ></el-input>
              <div class="codeImg" @click="refreshCode">
                <s-identify :identifyCode="identifyCode"></s-identify>
              </div>
            </el-form-item>
            <el-button type="primary" @click="goPay('ruleForm')"
              >Reload</el-button
            >
          </el-form>
        </el-col>
        <el-col :span="13" :offset="2">
          <ul class="list">
            <li
              class="item"
              v-for="(item, index) in cardList"
              :key="index"
              @click="goCard(item.url)"
            >
              <img :src="item.img" alt />
            </li>
          </ul>
        </el-col>
      </el-row>
    </div>
    <el-row class="container2">
      <h2 class="title">About Coupon</h2>
      <p>
        After you get the coupon number, please enter the coupon number in the
        text box and click ‘Reload’ button. After the balance is used up, you
        can reload again by purchase coupon.
        <em>The reload coupons are not refundable.</em>
      </p>
      <p>
        Multiple coupons can be used together or stacked for the same order. If
        you reload with multiple coupons, website will keep update you about
        your remaining account
        <em>balance.</em>
      </p>
      <p>
        In order to avoid fraudulent activities such as malicious order, refund
        and etc., (such as give bad review intentionally without any reason),
        all users whom request for refund will be automatically blacklisted and
        they will never be allowed to place an order in .
      </p>
    </el-row>
    <el-row class="container3">
      <el-table :data="tableData">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column label="Order ID" prop="paySerial"></el-table-column>
        <el-table-column
          label="Payment Method"
          :formatter="payFormat"
        ></el-table-column>
        <el-table-column
          label="Reload Amount"
          prop="payAmount"
        ></el-table-column>
        <el-table-column label="Reload Time" prop="payTime"></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalSize"
        v-if="totalSize > 10"
      ></el-pagination>
    </el-row>
  </div>
</template>

<script>
import { doCharge } from "../../api/charge";
import countdown from '@/components/tempcount/countdown.vue';
import { getChargeList } from "../../api/order";
import { message } from "@/utils/resetMessage";
import SIdentify from "../../components/identify/identify";
import { getSessionUser } from "../../api/auth";
export default {
  components: {
    SIdentify,
    countdown
  },
  data() {
    return {
      ruleForm: {
        cardKey: "",
        imageCode: "",
      },
      isLoggedIn: false,
      account: "",
      balance: "",
      vipTime: "",
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      info:{
        currentTime:'',
        startDate:'',
        endDate:'',
        isFlag:false //是否显示
      },
      totalSize: 0,
      cardList: [],
      rules: {
        cardKey: [
          {
            required: true,
            message: "Paste your coupon number here",
            trigger: "blur",
          },
        ],
        imageCode: [
          {
            required: true,
            message: "Enter captcha code here",
            trigger: "blur",
          },
        ],
      },
      identifyCodes: "1234567890",
      identifyCode: "",
    };
  },
  mounted() {
    this.isLoggedIn =
      (this.$store.state.user.token !== null ||
        this.$store.state.user.token !== "") &&
      this.$store.state.user.loginNameType != null
        ? true
        : false;
    this.getUserList();
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
    this.getCardList();
    this.getTableData();
  },
  methods: {
    payFormat(row) {
      if (row.payWay === 1) {
        return "Card";
      } else {
        return "Bank";
      }
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ];
      }
    },
    getUserList() {
      getSessionUser({})
        .then((result) => {
          this.$store.dispatch("setUserInfo", result);
          if (this.$store.state.user.loginNameType) {
            this.account =
              this.$store.state.user.loginNameType == 1
                ? this.$store.state.common.userInfo.mobile
                : this.$store.state.common.userInfo.mail;
            this.balance = this.$store.state.common.userInfo.blance;
            if (this.$store.state.common.userInfo.vipEndTime == null) {
              this.info.isFlag = false;
            } else {
              this.info.currentTime = new Date();
              this.info.startDate = new Date();
              this.info.endDate = new Date(this.$store.state.common.userInfo.vipEndTime);
              this.info.isFlag = true;
            }
          }
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
    },

    getCardList() {
      this.cardList = [
        {
          img: "",
          url: "",
        },
        {
          img: "",
          url: "",
        },
        {
          img: "",
          url: "",
        },
      ];
    },
    getTableData() {
      let dataform = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        type: 1,
      };
      //获取充值历史列表
      getChargeList(dataform)
        .then((result) => {
          // 返回到上一个页面
          this.tableData = result.list;
          this.totalSize = result.count;
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
    },
    //充值界面
    goPay(formName) {
      if (this.isLoggedIn) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.ruleForm.imageCode == this.identifyCode) {
              //进行充值
              doCharge(this.ruleForm)
                .then((result) => {
                  // 返回到上一个页面
                  message.success({
                    message: "Reload Success.",
                    duration: 4000,
                    offset: 80,
                  });
                  this.$store.dispatch("setUserInfo", result);
                  this.ruleForm = {};
                  this.getUserList();
                  this.getTableData();
                })
                .catch((error) => {
                  message.error({
                    message: "Reload error, please try again.",
                    duration: 4000,
                    offset: 80,
                  });
                  this.refreshCode();
                });
            } else {
              message.error({
                message: "The verification code is incorrect. Please re-enter.",
                duration: 4000,
                offset: 80,
              });
              this.refreshCode();
            }
          } else {
            return false;
          }
        });
      } else {
        message.error({
          message: "Pleas Log In",
          duration: 4000,
          offset: 80,
        });
      }
    },
    goCard(url) {
      window.open(url, "_blank");
    },
    linkCardStockPay() {
      this.$router.push({ path: "/pay" });
      this.$store.commit("asideMenuId", 2);
    },
    timestampToTime (time) {// 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var date = new Date(time) 
      let y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? ('0' + MM) : MM      
      let d = date.getDate()
      d = d < 10 ? ('0' + d) : d      
      let h = date.getHours()
      h = h < 10 ? ('0' + h) : h      
      let m = date.getMinutes()
      m = m < 10 ? ('0' + m) : m      
      let s = date.getSeconds()
      s = s < 10 ? ('0' + s) : s      
      return y + '-' + MM + '-' + d + '-'+' '+h+':'+m+':'+s;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getTableData();
    },
    handleCurrentChange(val) {
      console.log("jjj" + val);
      this.currentPage = val;
      this.getTableData();
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.pay {
  padding: 25px 40px 40px;
  margin-bottom: 20px;
  background: #fff;
  .pay-header {
    padding-top: 8px;
    padding-bottom: 15px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e8e8e8;
    span {
      color: #767676;
      em {
        color: #000;
        font-size: 16px;
      }
      &:nth-child(2) {
        margin-left: 15px;
      }
    }
    .fr {
      float: right;
      font-size: 14px;
      color: #909090;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.container1 {
  .item {
    margin-bottom: 20px;
    position: relative;
    p {
      display: block;
      font-size: 14px;
      color: #000;
      .iconfont {
        margin-right: 10px;
        font-size: 16px;
      }
    }
    .input {
      width: 100%;
      height: 40px;
      border: none;
      border-bottom: 1px solid #767676;
    }

    .inputCode {
      width: 80%;
    }
    .codeImg {
      position: absolute;
      right: 0;
      bottom: 15px;
      width: 160px;
      height: 50px;
      border: 1px solid #e5e5e5;
    }
  }
  .el-button {
    margin-top: 40px !important;
    padding: 12px 25px;
    height: 44px !important;
  }
  .list {
    li {
      margin-bottom: 10px;
      float: left;
      width: 240px;
      cursor: pointer;
      img {
        width: 90%;
      }
    }
  }
}
.container2 {
  margin-bottom: 20px;
  background: #fff;
  border: 1px solid #feaf06;
  padding: 5px 35px 15px;
  .title {
    margin-bottom: 16px;
    line-height: 50px;
    font-size: 16px;
    color: #1f1f1f;
    border-bottom: 1px solid #e8e8e8;
  }
  p {
    margin-bottom: 15px;
    line-height: 20px;
    color: #898989;
  }
}
.container3 {
  margin-bottom: 60px;
}
</style>
